.m-heading {
  font-size: 14px;
  color: #061e40;
  font-weight: 600;
}
.m-text {
  color: #667b99;
  font-size: 12px;
}
.tab-btn.active {
  border-bottom: 2px solid #061e40 !important;
}
/* .active {
  border-bottom: 2px solid #061e40;
} */
.input-form {
  /* border-bottom: 1px solid #9BA6A7; */
  padding: 10px 0px;
  width: 100%;
}
.input-form label {
  color: #667b99;
  font-size: 14px;
}
.input-form input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}
.input-form input::placeholder {
  font-size: 13px;
  color: #667b99;
}
.input-form input:focus {
  outline: none;
  box-shadow: none;
  border-color: #9ba6a7;
}
.input-form select {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  color: #667b99;
  font-size: 13px;
}
.input-form select:focus {
  outline: none;
  box-shadow: none;
  border-color: #9ba6a7;
}
.user-profile-modal .user-profile-model-content .d-btn button {
  padding: 20px;
  margin: 10px 0px;
  width: 100%;
  text-transform: capitalize;
}
.user-profile-modal .user-profile-model-content .nominee-scrol{
    height: 350px;
    overflow-x: hidden;
}

/* User-detail-modal */
.user-detail .user-profile-model-content .user-detail-tabs {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #dedede;
}
.user-detail .user-profile-model-content .user-detail-tabs .tab-btn {
  background-color: transparent;
  border: none;
  padding: 5px 0px;
  border-bottom: 2px solid transparent;
  width: 50%;
  transition: all 0.3s ease;
}
.user-detail .user-profile-model-content .user-detail-tabs .tab-btn:hover {
  border-bottom: 2px solid #061e40;
}
.user-detail .user-profile-model-content .user-detail-tabs {
  margin-bottom: 10px;
}
/* Icon-styling */
.toggle-nominee-content .chvrn-down-style {
  padding: 4px;
  border: 1px solid #9ba6a7;
  border-radius: 50%;
  color: #9ba6a7;
  font-size: 12px;
}
.user-detail .user-profile-model-content .toggle-nominee-content {
  margin-bottom: 10px;
  border: 1px solid #ededed;
  border-radius: 5px;
}
.user-detail .user-profile-model-content .nominee-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  background-color: transparent;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
  border: none;
}
.user-detail
  .user-profile-model-content
  .toggle-nominee-content
  .nomine-fields {
  border-top: 1px solid #dedede;
  padding: 0px 10px;
}
/* .user-detail .user-profile-model-content .nominee-button  .input-form{
    width: 100%;
} */
