@import url("../fonts/satoshi.css");

* {
  outline: none;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Satoshi-Regular" !important;
  overflow-x: hidden;
  font-weight: 400;
}

a,
span {
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

p,
ul {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
}

.dis-desk {
  display: block !important;
}

.dis-mobile {
  display: none !important;
}

.main-padding {
  padding: 60px 0px;
}

header {
  padding: 15px 0px;
  background-color: transparent;
  position: fixed;
  width: 100%;
}

.header-wraper .nav-btn-flex {
  display: flex;
  align-items: center;
  column-gap: 100px;
}

.header-wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-wraper .navList {
  display: flex;
  column-gap: 30px;
  align-items: center;
}

.headerDropdownbtn {
  background: none !important;
  border: none !important;
  cursor: pointer;
  padding: unset !important;
}

.dropdownmenu {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border: none !important;
  min-width: 200px !important;
  z-index: 100 !important;
  background: #fff;
  right: 0 !important;
  left: auto !important;
}

.dropdownmenu .dropdown-item {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  border-bottom: 0.3px solid rgba(155, 166, 167, 1) !important;
}

.dropdownmenu .dropdown-item img {
  margin-right: 15px !important;
}

.dropdownmenu .dropdown-item:last-child {
  border-bottom: unset !important;
}

.user__avatar {
  border-radius: 100% !important;
  width: 45px !important;
  height: 45px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}

.header-wraper .navList li a {
  color: #000;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  transition: 0.3s linear;
}

.header-wraper .navList li a:hover {
  color: #12c2d0;
}

.d-btn button,
.d-btn a {
  background-color: #061e40;
  border: none;
  padding: 10px 20px;
  color: #ffff;
  font-size: 12px;
  font-family: "Satoshi-bold";
  border-radius: 6px;
  letter-spacing: 2px;
  transition: 0.3s linear;
  text-transform: uppercase;
  text-decoration: none;
}

.d-btn button:hover,
.d-btn a:hover {
  opacity: 0.7;
}

.navList .d-btn button {
  padding: 10px 40px;
}


.header-wraper .logo img {
  width: 80px;
}

header.header-sec.navbar-scrolled {
  position: fixed;
  top: 0;
  left: 0;
  background: #ddf8fa;
  padding: 11px 0px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  z-index: 100;
}

/* banner  */
.banner-section {
  width: 100%;
  background-image: url("../Assets/imgs/hero-bg.webp");
  background-size: cover;
  padding-top: 120px;
}

.banner-img img {
  /* transform: scale(1.2); */
  margin-top: 40px;
}

.satisfaction-section.dis-desk {
  display: flex !important;
}

.banner-section .banner-heading {
  color: #061e40;
  font-size: 48px;
  font-family: "Satoshi-bold";
  margin-top: 100px;
  line-height: 56px;
}

.banner-section .banner-heading span {
  color: #01cdde;
}

.banner-section .banner-description {
  font-size: 17px;
  color: #000000;
  font-weight: 400;
  line-height: 28px;
  margin: 20px 0px 30px;
  width: 100%;
  max-width: 82%;
}

.d-btn.banner-btn button,
.d-btn.banner-btn a {
  /* background-color: #01cdde; */
  padding: 15px 20px;
  text-transform: uppercase;
}

.satisfaction-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.satisfaction-section {
  margin-top: 100px;
  width: 100%;
  max-width: 88%;
}

.satisfaction-section .sat-box ._tnum {
  font-size: 20px;
  line-height: 28px;
  font-family: "Satoshi-bold";
  color: #010101;
}

.satisfaction-section .sat-box p {
  font-weight: 400;
  font-size: 14px;
  color: #010101;
}

.satisfaction-section .v-line {
  background-color: #98e089;
  width: 1px;
  height: 70px;
}

/* trusted section */
.trusted-customer.main-padding {
  padding: 80px 0px 100px;
}

.trusted-customer {
  position: relative;
}

.trusted-customer .trusted-text {
  color: #061c3d;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
}

.trusted-customer .trusted-text span {
  color: #98e089;
}

.trusted-customer .logo-wraper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  align-items: center;
  margin: 40px auto 0px;
}

.trusted-customer .logo-wraper img {
  height: 60px;
  width: auto;
}

.main-heading {
  font-size: 34px;
  color: #343434;
  font-family: "Satoshi-bold";
  line-height: 44px;
}

/* offer insurance */
.offer-insurence {
  position: relative;
}

.tc-abs-img {
  position: absolute;
  top: -50%;
  left: 0;
}

.offer-insurence .tc-abs-img img {
  width: 350px;
}

.offer-insurence .main-heading {
  text-align: center;
}

.swiper-button-next {
  bottom: 30px !important;
  top: unset !important;
  right: 46% !important;
}

.swiper-button-prev {
  bottom: 30px !important;
  top: unset !important;
  left: 47% !important;
}

.swiper-button-next::after {
  content: "" !important;
  background-image: url("../Assets/imgs/swiper-next.webp");
  min-width: 60px;
  min-height: 60px;
  background-size: cover;
}

.swiper-button-prev::after {
  content: "" !important;
  background-image: url("../Assets/imgs/swiper-prev.webp");
  min-width: 60px;
  min-height: 60px;
  background-size: cover;
}

.swiper-card {
  box-shadow: 0px 10px 20px 0px #0000000d;
  padding: 25px 20px;
  margin-bottom: 20px;
  border-radius: 12px;
  background: #fff;
}

.swiper-card.download-card {
  cursor: pointer;
}

.swiper-card h3 {
  color: #061c3d;
  font-size: 17px;
  font-family: "Satoshi-bold";
  margin: 20px 0px 12px;
}

.swiper-card p {
  color: #42526b;
  font-weight: 400;
  font-size: 14px;
  min-height: 64px;
}

.offer-swiper .swiper {
  padding: 40px 20px 90px;
}

/* .swiper-card img {
  height: 80px;
} */
.swiper {
  position: relative;
}

.swiper::before {
  content: "";
  width: 270px;
  height: 550px;
  display: block;
  position: absolute;
  top: 4%;
  left: 42%;
  transform: rotate(-90deg);
  background-image: radial-gradient(#8df2ff1a,
      #8df2ff1a,
      #8df2ff1a,
      transparent,
      transparent);
}

/* claim section */
.claim-section {
  background-image: url("../Assets/imgs/claim-bg.webp");
  background-size: cover;
  position: relative;
}

.claim-section .claim-desc {
  font-size: 17px;
  color: #000000;
  font-weight: 400;
  margin: 30px 0px;
  width: 100%;
  line-height: 26px;
  max-width: 87%;
}

.claim-section .our-exp {
  font-size: 17px;
  color: #000000;
  font-weight: 400;
  display: flex;
  column-gap: 15px;
  align-items: center;
  margin-bottom: 15px !important;
}

.claim-section .our-exp img {
  width: 26px;
}

.claim-section .claimImg {
  width: 340px;
  display: block;
  margin: 0 auto;
}

.col-md-7 .ml {
  margin-left: 70px;
}

.claim-section .claim_text_btn p {
  margin-bottom: 15px !important;
}


/* Testimonial_styling */
.testimonial-section .main-heading {
  text-align: center;
}

.testimonial-section .testimonial_swipper .testimonial_box {
  position: relative;
}

.testimonial-section .testimonial_swipper .testimonial_box .testimonial_card .test_content {
  font-size: 14px;
  background: #FFFFFF;
  border: 0.45px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  padding: 31px;
  text-align: center;
  color: #42526B;
}

.testimonial-section .testimonial_swipper .testimonial_box .testimonial_card .test_img_text {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 14px 0px;
}

.testimonial-section .testimonial_swipper .testimonial_box .testimonial_card .test_img_text .test_img img {
  border-radius: 50%;
}

.testimonial-section .testimonial_swipper .testimonial_box .testimonial_card .test_img_text .test_name {
  font-weight: 600;
}

.testimonial-section .testimonial_swipper .testimonial_box .testimonial_icon {
  position: absolute;
  top: -22px;
  right: 45%;
}

/* Testimonial_styling_end */










/* policy section */
/* .faq_section {
  background-image: url("../Assets/imgs/buy-policy-bg.webp");
  background-size: cover;
  background-position: left;
} */

.faq_section .main-heading {
  text-align: center;
}

.faq_section .accordion {
  margin-top: 60px;
}

.faq_section .accordian_container .d-btn {

  padding: 15px 0;
}

.faq_section .accordion .accordion-item {
  margin-bottom: 20px;
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

.faq_section .accordion .accordion-item .accordion-header button {
  font-size: 16px;
  font-weight: 600;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 18px 12px;
}

.faq_section .accordion .accordion-item .accordion-header .accordion-button:focus {
  box-shadow: none;
}

.faq_section .accordion .accordion-item .accordion-body {
  color: #42526B;
  font-size: 14px;
}

.faq_section .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #000000;
}


/* Customer_support_styling */

.contact_section .main-heading {
  text-align: center;
}

.contact_section .contact_content {
  text-align: center;
  margin: 10px 0px 20px 0px;
  font-size: 14px;
  color: #42526B;
}

.contact_section .contact_box {
  display: flex;
  gap: 16px;
  align-items: baseline;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 14px 20px;
  border-radius: 12px;
  margin-top: 20px;
}

.contact_section .contact_box .contact_icon_img img {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.contact_section .contact_box .contact_text .c_name {
  font-weight: 600;
  font-size: 20px;
}

.contact_section .contact_box .contact_text .helpl_text {
  color: #808080;
  font-size: 14px;
  margin-top: 8px;
}

.contact_section .contact_box .contact_text .helpl_number {
  font-size: 14px;
}

/* Customer_support_styling_End */
/* download */
.downloadnow {
  padding-top: 60px;
}

.dowmload-wraper {
  position: relative;
}

.dowmload-wraper img {
  width: 100%;
}

.dowmload-wraper .download-abs-content {
  position: absolute;
  top: 27%;
  left: 43%;
}

.dowmload-wraper .download-content {
  margin: 20px 0px;
  font-size: 15px;
  width: 100%;
  max-width: 68%;
}

.dowmload-wraper .downloadlogo {
  width: 80px;
}

.download-abs-content .main-heading {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

/* footer */
.footer-section {
  margin-top: 60px;
  border-top: 1px solid #ededed;
}

.footer-section .foot-desc {
  font-size: 14px;
  font-weight: 400;
  color: #676767;
}

.footer-section .footlogo img {
  width: 80px;
  margin-bottom: 20px;
}

.footer-section .footheading {
  color: #363940;
  font-size: 18px;
  font-family: "Satoshi-bold";
  text-align: center;
  margin-bottom: 14px;
}

.footer-section .footlink a {
  color: #676767;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 14px;
  font-weight: 400;
  text-decoration: none;
  transition: 0.3s linear;
}

.footer-section .foot-social-icon a {
  color: #676767;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 20px;
  font-weight: 400;
  text-decoration: none;
  transition: 0.3s linear;
}

.footer-section .footlink a:hover {
  color: #01cdde;
}

.footer-section .footlink {
  text-align: left;
}

.foot-social-icon {
  display: flex;
  align-items: center;
}

.foot-social-icon a {
  transition: 0.3s linear;
  margin-bottom: 20px;
}

.foot-social-icon a img {
  width: 50px;
}

.foot-social-icon a img:hover {
  opacity: 0.7;
}

.social-wraper {
  width: 65%;
  margin-left: auto;
}

.social-wraper .footheading {
  text-align: left;
}

.footer-section .col-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-section.main-padding {
  padding-bottom: 30px;
}

.footer-section .copyright a,
.footer-section .copyright {
  color: #000000;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 14px;
  text-align: center;
  margin-top: 50px;
  text-underline-offset: 3px;
}

.footer-section .copyright .dot {
  width: 3px;
  height: 3px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}


/* FAQ_PAGE_STYLING */
.faq_page_section .main-heading {
  text-align: center;
  margin: 60px 0px;
}

.faq_page_section .faq_content {
  margin: 5px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.faq_page_section .faq_content .faq_heading {
  font-size: 18px;
  font-weight: 600;
  color: #061C3D;
}

.faq_page_section .faq_content .faq_text {
  color: #42526B;
  font-size: 16px;
  padding: 5px 0px 18px 0px;
}

.faq_page_section .f_list {
  padding: 5px 0px 10px 0px;
}

.faq_page_section .f_list .faq_list_content ul li {
  font-size: 15px;
  color: #42526B;
  list-style-type: disc;
  margin: 5px 0px 0px 25px;
}

.faq_page_section .f_list .faq_list_content .faq_text {
  padding-bottom: 0px;
}

.faq_page_section .f_list .num_list ul li {
  list-style-type: number;
}

.faq_page_section .f_list .faq_list_content .circle_list li {
  list-style-type: circle;
  margin: 5px 0px 0px 50px;
}

/* FAQ_PAGE_STYLING_End */










/* modal */
.modal-bg {
  background-image: url("../Assets/imgs/popup-bg.webp");
  position: relative;
  background-position: center;
  background-size: cover;
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding-left: 40px;
}

.modal-bg .pop-up-right-image {
  padding: 50px 80px 0px 0px;
}

.modal-bg .modal-left-content .popup-logo-img img {
  width: 80px;
}

.modal-bg .close-modal-button img {
  width: 20px;
}

.modal-open {
  padding-right: 0px !important;
}

.modal-body {
  padding: 0px;
}

.modal .modal-content {
  border-radius: 12px !important;
  border: none !important;
  /* background-color: transparent; */
}

.modal-left-content {
  /* padding: 30px 50px; */
  width: 100%;
  position: relative;
}

.modal-left-content .pop-up-right-image img {
  width: 309px;
}

.modal-left-content .main-heading {
  font-size: 30px;
  font-weight: bold;
  margin: 10px 0px;
}

.modal-left-content .main-content {
  width: 86%;
  line-height: 22px;
  color: #42526b;
  font-weight: 400;
  font-size: 15px;
  position: relative;
}

.modal-left-content .main-content .popup-arrow-image {
  position: absolute;
  top: 18px;
  right: -21px;
}

.modal-left-content .d-default {
  width: -moz-fit-content;
  width: fit-content;
  background-color: #01cdde;
  display: flex;
  color: #ffffff;
  text-decoration: none;
  margin-top: 20px;
  border-radius: 4px;
  padding: 8px 18px;
  transition: all 0.1s;
  border: 1px solid transparent;
  transition: all 0.2s ease;
}

.modal-left-content .d-default:hover {
  color: #01cdde;
  background-color: #ffffff;
  border: 1px solid #01cdde;
}

.modal-left-content .d-default span {
  font-size: 12px;
  font-weight: 500;
}

.close-modal-button {
  position: absolute;
  right: 45px;
  top: 26px;
}

.close-modal-button:hover {
  cursor: pointer;
  opacity: 0.7;
}

/* modal */
/* new modals */
.modal-parent {
  background-color: #fff;
  border-radius: 16px;
}

.modal-content-body {
  display: flex;
  column-gap: 20px;
  padding: 30px;
}

.swiper-card {
  cursor: pointer;
}

.download-btn a {
  border-radius: 66px;
  text-align: center;
  text-decoration: none;
  border: 0.47px solid rgba(0, 0, 0, 1);
  padding: 12px 30px;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-family: "Satoshi-bold";
  transition: 0.3s linear;
  margin-top: 15px;
}

.new-modal-heading {
  font-size: 22px;
  font-family: "Satoshi-bold";
  margin-bottom: 6px;
}

.modal-desc {
  color: rgba(69, 69, 69, 1);
  font-weight: 400;
  margin: 10px 0px 30px;
}

._m-img img {
  min-width: 100px;
  height: 100px;
}

.modal-content-body.bd-top {
  border-top: 1px solid #ccc;
}

.copyright br {
  display: none !important;
}

@media (max-width: 767px) {
  .d-btn button {
    border-radius: 2px;
  }

  .main-heading {
    font-size: 20px;
    line-height: 28px;
  }

  .main-padding {
    padding: 40px 0px;
  }

  .dis-desk {
    display: none !important;
  }

  .dis-mobile {
    display: block !important;
  }

  .nav-btn-flex.active .navList {
    display: block !important;
  }

  .header-wraper .navList {
    background-image: url("../Assets/imgs/menu-bg.webp");
    position: absolute;
    width: 280px;
    top: 49px;
    right: 15px;
    padding: 20px 30px !important;
    display: none !important;
    background-size: cover;
    border-radius: 16px;
    z-index: 22;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }

  .navList li {
    margin-bottom: 20px;
  }

  .close-flex {
    display: flex !important;
    align-items: center;
    padding-bottom: 30px;
    justify-content: space-between;
  }

  .close-flex .mob-logo img {
    width: 60px;
  }

  .close-btn img {
    width: 15px;
  }

  .satisfaction-section {
    margin-top: 40px;
    max-width: 100%;
  }

  .header-wraper .logo img {
    width: 60px;
  }

  header {
    position: relative;
    padding: 10px 0px;
  }

  .banner-section .banner-heading {
    margin-top: 0px;
  }

  .banner-section {
    padding-top: 20px;
  }

  .banner-section .banner-heading {
    margin-top: 0px;
    text-align: center;
  }

  br {
    display: none;
  }

  .tc-abs-img {
    top: -60%;
  }

  .claim-section {
    background-position: right top;
  }

  .offer-insurence .tc-abs-img img {
    width: 250px;
  }

  .banner-section .banner-heading {
    font-size: 37px;
    line-height: 46px;
    padding: 0px 7px;
  }

  .banner-section .banner-description {
    margin: 20px auto 30px;
    max-width: 100%;
    text-align: center;
  }

  .d-btn.banner-btn {
    text-align: center;
  }

  .satisfaction-section.dis-mobile {
    display: flex !important;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    margin-top: 75px;
  }

  .satisfaction-section .sat-box ._tnum {
    font-size: 16px;
    line-height: 24px;
  }

  .satisfaction-section .sat-box p {
    font-size: 12px;
  }

  .satisfaction-section .v-line {
    width: 1px;
    height: 45px;
  }

  .trusted-customer {
    z-index: 1;
  }

  .trusted-customer.main-padding {
    padding: 45px 0px 45px;
  }

  .trusted-customer .logo-wraper {
    max-width: 100%;
    column-gap: 40px;
    margin: 20px auto 0px;
    overflow: auto;
    padding-bottom: 15px;
  }

  .__customerIcon {
    min-width: 135px;
    width: fit-content;
  }

  .__customerIcon img {
    width: auto !important;
    min-width: auto;
    height: 50px !important;
  }

  .logo-wraper::-webkit-scrollbar {
    width: 2px;
    height: 4px;
  }

  /* Track */
  .logo-wraper::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .logo-wraper::-webkit-scrollbar-thumb {
    background: #36d6e4;
  }

  .trusted-customer .trusted-text {
    font-size: 20px;
  }

  .swiper-button-next {
    right: 41% !important;
  }

  .swiper-button-prev {
    left: 40% !important;
  }

  .offer-swiper .swiper {
    padding: 40px 5px 90px;
  }

  .claim-section .claimImg {
    width: 130px;
    position: absolute;
    right: 8px;
    bottom: 10px;
  }

  .col-md-7 .ml {
    margin-left: unset;
  }

  .testimonial-section .testimonial_swipper .testimonial_box .testimonial_card .test_content {
    font-size: 14px;
  }

  .testimonial-section .testimonial_swipper .testimonial_box .testimonial_card .test_img_text .test_name {
    font-size: 14px;
  }

  .faq_section .accordion .accordion-item .accordion-header button {
    padding: 15px 10px;
    font-size: 13px;
    line-height: 18px;
  }

  .faq_section .accordion .accordion-item .accordion-body {
    font-size: 13px;
    padding: 12px;
  }

  .contact_section .contact_box .contact_text .c_name {
    font-size: 14px;
  }

  .contact_section .contact_box {
    padding: 10px;
  }

  .faq_section .accordian_container .d-btn {
    text-align: center;
  }

  .claim-section .claim_text_btn p {
    font-size: 13px;
    max-width: 60%;
  }

  .claim-section .claim-desc {
    max-width: 100%;
    margin: 20px 0px;
    font-size: 14px;
    line-height: 24px;
  }

  .claim-section .our-exp {
    font-size: 14px;
  }

  .buy-policy .policy-desc {
    font-size: 13px;
    max-width: 100%;
    margin: 12px 0px 25px;
  }

  .policy-btn-wraper .learnmore-btn button,
  .d-btn.banner-btn button {
    font-size: 10px;
  }

  img.buypolicymainImg {
    width: 250px;
    margin-left: auto;
    display: block;
  }

  .banner-img img {
    transform: unset;
    margin-top: 40px;
  }

  .dowmload-wraper .download-abs-content {
    left: 0;
    width: 99%;
    text-align: center;
    bottom: 15px;
    top: auto;
  }

  .dowmload-wraper .downloadlogo {
    width: 25px;
  }

  .download-abs-content .main-heading {
    gap: 5px;
    font-size: 14px;
    justify-content: center;
  }

  .dowmload-wraper .download-content {
    max-width: 100%;
    font-size: 13px;
    margin: 5px 0px;
    padding: 0 30px;
  }

  .dowmload-wraper .d-btn {}

  .dowmload-wraper .d-btn button {
    font-size: 11px;
    padding: 7px 10px;
  }

  .footer-section .footlink a {
    margin-bottom: 10px;
  }

  .footer-section .col-row {
    display: block;
  }

  .footer-section .footheading {
    text-align: left;
    margin-bottom: 15px;
  }

  .footer-section .footlogo img {
    margin-bottom: 15px;
  }

  .footer-section .foot-desc {
    font-size: 11px;
  }

  .social-wraper {
    margin-left: unset;
    width: 100%;
  }

  .footer-section .copyright {
    margin-top: 10px;
  }

  .satisfaction-section.dis-desk {
    display: none !important;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: none;
    z-index: 21;
  }

  .overlay.show {
    display: block;
  }

  /* modal */
  .modal-bg {
    flex-direction: column;
    padding: 0px;
  }

  .modal-left-content {
    padding: 50px 10px 0px 10px;
    text-align: center;
  }

  .modal-bg .pop-up-right-image {
    padding: 26px 10px 0px 10px;
    text-align: center;
  }

  .modal-bg .pop-up-right-image img {
    width: 70%;
  }

  .modal-left-content .main-content {
    margin: auto;
    width: 100%;
  }

  .modal-left-content .d-default {
    margin: auto;
    margin-top: 12px;
  }

  .close-modal-button {
    right: 30px;
  }

  /* modal */
  .copyright br {
    display: block !important;
  }

  .mob-flex {
    display: flex;
    justify-content: space-between;
    margin-top: 30px !important;
  }

  .social-wraper.pd-left {
    padding-left: 15px;
  }
}

@media (max-width: 380px) {
  .swiper-button-next {
    right: 38% !important;
  }
}

.error-msg {
  color: red;
  font-size: 14px;
  text-align: center;
}