body{ margin:0px; font-family: 'Roboto', sans-serif;}

.jazz_discount_outer{float: left; margin: 0px; padding: 0px; width: 100%;}

.discount_inner{float: left; margin: 0px; padding: 0px; width: 100%;}

.discount_inner h2 {
    float: left;
    width: 100%;
    position: relative;
    background-color: #f8f8f8;
    padding: 20px;
    border: solid 1px #cccccc;
    margin: 20px 0;
    font-size: 28px;
    color: #ee1d23;
}


.discount_inner h3{
    float: left;
    width: 100%;
    color: #ee1d23;
    margin: 0px 0 10px 0;
    font-size: 18px;
    font-weight: 600;
}

.discount_inner p{
    float: left;
    width: 100%;
    color: #000;
    margin: 0px 0 18px 0 !important;
    font-size: 14px;
    font-weight: 400;
}

.discount_inner a{
    color: #ee1d23;
}

.jazz_discount_outer ul{
    padding-left: 30px !important;
    margin-bottom: 10px !important;
    clear: both;
}
.jazz_discount_outer li{
    text-align: left;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    list-style-type: disc;
}