.landingBimaSection {
  background-image: url("../Assets/imgs/landingBimaBg.webp");
}
.landingBimaSection .fikr-landing-form .fikr-form {
  box-shadow: 4.94px 9.04px 26.93px 3.01px rgba(169, 224, 249, 0.35);
  background-color: rgba(255, 255, 255, 0.68) !important;
  margin-top: -23px !important;
}
.landing-sectionOne.landingBimaSection
  .landing-content
  .fikr-landing-form::before {
  background: none !important;
  content: none;
}
.landing-sectionOne.landingBimaSection .landing-content .fikr-landing-form {
  background: unset !important;
  backdrop-filter: blur(5px);
}
.landing-sectionOne.landingBimaSection
  .landing-content
  .fikr-landing-form
  .fikr-form
  .text {
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
}
.landing-sectionOne.landingBimaSection
  .landing-content
  .fikr-landing-form
  .fikr-form
  .fikr-input
  input {
  color: rgba(0, 0, 0, 1);
  border: 2px solid rgba(9, 122, 154, 0.6);
  padding: 14px;
  background-color: rgba(255, 255, 255, 1);
  border: 2px solid #e6f2fd;
}
.landing-sectionOne.landingBimaSection
  .landing-content
  .fikr-landing-form
  .fikr-form
  .fikr-input
  span {
  padding: 14px;
  background-color: rgba(255, 255, 255, 1);
  border: 2px solid #e6f2fd;
  color: #000;
  font-weight: 500;
}
.landing-sectionOne.landingBimaSection
  .landing-content
  .fikr-landing-form
  .fikr-form
  button {
  background: linear-gradient(
    65.06deg,
    #98e089 -18.83%,
    #01cdde 57.46%,
    #00adc9 136.87%
  );
  border-radius: 30px;
  font-weight: 700;
  color: #fff;
  font-size: 17px;
  padding: 10px 20px;
}
.landing-sectionOne.landingBimaSection
  .landing-content
  .fikr-landing-form
  .fikr-form
  .charges-text {
  color: #000;
  font-weight: 600;
}
.landing-sectionOne.landingBimaSection .bimapolicybenifits ul li {
  color: rgba(110, 110, 110, 1);
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  margin-bottom: 7px;
  list-style-type: disc;
}
.landing-sectionOne.landingBimaSection .bimapolicybenifits ul li:last-child {
  margin-bottom: 0px;
}
.landing-sectionOne.landingBimaSection .bimapolicybenifits ul li::marker {
  color: #a0ced9;
}
.landing-sectionOne.landingBimaSection .bimapolicybenifits {
  margin: 15px 0px 10px;
}
.landing-sectionOne.landingBimaSection
  .landing-content
  .fikr-landing-form
  .fikr-form
  .term-conditon
  a {
  text-decoration: none;
  color: rgba(159, 159, 159, 1);
  transition: all 0.3s ease;
}
.landing-sectionOne.landingBimaSection .landing-content .landing-home-img {
  z-index: unset !important;
}
