
@font-face {
  font-family: 'Satoshi-Regular';
  src:  url('../fonts/Satoshi-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}
@font-face {
  font-family: 'Satoshi-medium';
  src:  url('../fonts/Satoshi-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}
@font-face {
  font-family: 'Satoshi-bold';
  src:  url('../fonts/Satoshi-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}
@font-face {
  font-family: 'Satoshi-light';
  src:  url('../fonts/Satoshi-Light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}



