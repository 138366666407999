@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500&display=swap");

* {
  outline: none;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

a,
span {
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

p,
ul {
  margin: 0;
  padding: 0;
}
.SignUpLandingPage{
  min-height: 100vh;
}
.SignUpLandingPage .back__arrow {
  position: absolute;
  left: 20px;
  top: 20px;
  transition: 0.2s linear all;
  cursor: pointer;
}
.SignUpLandingPage .desktopBg {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.SignUpLandingPage .desktopBg img {
  width: 100%;
}
.SignUpLandingPage .back__arrow:hover {
  opacity: 0.7;
}
.SignUpLandingPage .landing-content .fikr-landing-form .fikr-form .text {
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
}
.SignUpLandingPage .landing-content {
  max-width: 375px;
  color: #ffffff;
  margin: auto;
  text-align: center;
  padding: 20px 5px;
}

.SignUpLandingPage .landing-content .logo-img {
  margin-bottom: 10px;
}

.SignUpLandingPage .landing-content .landing-home-img {
  position: relative;
  z-index: 1000;
}

.SignUpLandingPage .landing-content .fikr-landing-form {
  position: relative;
  z-index: 10;
  background: #fffefd;
  box-shadow: 0px 194.401px 474.149px rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(25.6193px);
  border-radius: 20px;
}

.SignUpLandingPage .landing-content .fikr-landing-form::before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180.05deg,
    #097a9a 0.05%,
    rgba(9, 122, 154, 0) 118.29%
  );
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 1px;
  z-index: -1;
  border-radius: 20px;
}

.SignUpLandingPage .landing-content .fikr-landing-form .fikr-form {
  padding: 10px 30px;
  background-color: #ffffff;
  border-radius: 20px;
  margin-top: -20px;
  width: 99.5%;
  position: relative;
}

.SignUpLandingPage
  .landing-content
  .fikr-landing-form
  .fikr-form
  .fikr-form-logo {
  margin-bottom: 15px;
}

.SignUpLandingPage .landing-content .fikr-landing-form .fikr-form .error {
  color: rgba(249, 71, 71, 1);
  margin: 0px 0px 10px !important;
  font-size: 13px;
}

.SignUpLandingPage .landing-content .fikr-landing-form .fikr-form .fikr-input {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  color: #ffffff;
  margin: 20px 0px;
  gap: 10px;
}

.SignUpLandingPage
  .landing-content
  .fikr-landing-form
  .fikr-form
  .fikr-input
  span {
  font-size: 13px;
  color: #6a6a6c;
  padding: 16px;
  background: rgba(18, 21, 28, 0.02);
  border: 2px solid rgba(9, 122, 154, 0.6);
  border-radius: 8px;
}

.SignUpLandingPage
  .landing-content
  .fikr-landing-form
  .fikr-form
  .fikr-input
  input {
  background: rgba(18, 21, 28, 0.02);
  color: #6a6a6c;
  font-size: 13px;
  border: 2px solid rgba(9, 122, 154, 0.6);
  padding: 16px 12px;
  width: 100%;
  border-radius: 8px;
}

.SignUpLandingPage .landing-content .fikr-landing-form .fikr-form button {
  width: 100%;
  border-radius: 6px;
  padding: 14px 20px;
  font-size: 14px;
  color: #ffffff;
  border: 1px solid transparent;
  background-color: #097a9a;
  font-weight: 700;
  transition: all 0.3s ease;
}

.SignUpLandingPage .landing-content .fikr-landing-form .fikr-form button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.SignUpLandingPage .landing-content .fikr-landing-form .fikr-form .text {
  color: #3d2525;
  font-size: 14px;
}

.SignUpLandingPage
  .landing-content
  .fikr-landing-form
  .fikr-form
  .charges-text {
  margin: 12px 0px 4px 0px;
  font-size: 13px;
  color: #393939;
}

.SignUpLandingPage
  .landing-content
  .fikr-landing-form
  .fikr-form
  .term-conditon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.SignUpLandingPage
  .landing-content
  .fikr-landing-form
  .fikr-form
  .term-conditon
  a {
  text-decoration: underline;
  color: #0c3d5c;
  transition: all 0.3s ease;
}

.SignUpLandingPage
  .landing-content
  .fikr-landing-form
  .fikr-form
  .term-conditon
  a:hover {
  opacity: 0.7;
}

.SignUpLandingPage
  .landing-content
  .fikr-landing-form
  .fikr-form
  .term-conditon
  .dot {
  border: 1px solid transparent;
  height: 5px;
  border-radius: 50px;
  width: 5px;
  background-color: #bcbcbc;
}

/* PIN page */
.SignUpLandingPage .landing-content .otp-form .fikr-input {
  margin: 15px 0px !important;
  gap: 25px !important;
}

.SignUpLandingPage
  .landing-content
  .fikr-landing-form
  .otp-form
  .fikr-input
  input {
  width: 61%;
  padding: 15px 10px !important;
  text-align: center !important;
  border: 1px solid rgba(18, 21, 28, 0.15) !important;
  border-radius: 8px !important;
}

.SignUpLandingPage .landing-content .fikr-landing-form .otp-form .charges-text {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.SignUpLandingPage
  .landing-content
  .fikr-landing-form
  .otp-form
  .charges-text
  span {
  font-weight: 600;
  margin-left: 2px;
}

#otresendSpan {
  cursor: pointer;
}
.dis-desk {
  display: block;
}
.dis-mobile {
  display: none;
}
.defaultloader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.defaultloader img {
  width: 200px;
  height: 200px;
}
@media (max-width: 767px) {
  .dis-desk {
    display: none;
  }
  .dis-mobile {
    display: block;
  }
}
