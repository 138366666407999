.profile-icon {
  text-align: center;
  position: relative;
  width: 150px;
  margin: auto;
}

img.user-profile-icon {
  position: relative;
  z-index: 2;
  object-fit: cover;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.profile-page {
  background-image: url('../Assets/imgs/profile-bg.webp');
  background-size: cover;
  background-position: center;
  padding: 80px 0px;
}



/* .inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
} */


.back-profile {
  display: flex;
  align-items: center;
}

.back-profile img {
  width: 25px;
}

.back-profile span {
  font-size: 22px;
  font-weight: 700;
}

.profile-icon img.camera {
  position: absolute;
  bottom: 6px;
  width: 50px;
  right: -10px;
  z-index: 3;
}

.profile-icon img.profileshadow {
  position: absolute;
  bottom: -12px;
  right: 0;
}


.input-form {
  /* border-bottom: 1px solid #9BA6A7;  */
  padding: 10px 0px;
  width: 100%;
}

.input-form label {
  color: #667b99;
  font-size: 14px;
}

.input-form input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}

.input-form input::placeholder {
  font-size: 13px;
  color: #667b99;
}

.input-form input:focus {
  outline: none;
  box-shadow: none;
  border-color: #9ba6a7;
}

.input-form select {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  color: #667b99;
  font-size: 13px;
}

.input-form select:focus {
  outline: none;
  box-shadow: none;
  border-color: #9ba6a7;
}

.justify-content-md-center .d-btn button {
  width: 100%;
  /* margin: 15px 0px; */
  padding: 20px 0px;

}

.justify-content-md-center .d-btn {
  margin-top: 50px;
}

.user-profile-loading {
  width: 150px;
  height: 150px;
}