.m-heading {
  font-size: 14px;
  color: #061e40;
  font-weight: 600;
}
.m-text {
  color: #667b99;
  font-size: 12px;
}
/*  */
/* .verfication-modal
  .modal-content
  .modal-body
  .verification-modal-content
  .m-text {
  color: #667b99;
  font-size: 12px;
} */
.verfication-modal
  .modal-content
  .modal-body
  .verification-modal-content
  .verification-number {
  display: flex;
  align-items: center;
  border: 1px solid #9ba6a7;
  padding: 10px 0px;
  font-size: 14px;
  border-radius: 8px;
  margin: 10px 0px;
}
.verfication-modal
  .modal-content
  .modal-body
  .verification-modal-content
  .verification-number
  input {
  position: relative;
  width: 100%;
  margin-left: 34px;
  padding: 5px 22px 5px 3px;
  border: none;
  color: #4a555f;
}
.verfication-modal
  .modal-content
  .modal-body
  .verification-modal-content
  .verification-number
  span {
  position: absolute;
  left: 18px;
  z-index: 999;
  background: #ffffff;
  padding: 5px;
}
.verfication-modal
  .modal-content
  .modal-body
  .verification-modal-content
  .verification-number
  .arrow-head-down {
  position: absolute;
  right: 27px;
  background: #ffffff;
  width: 30px;
}

.verfication-modal
  .modal-content
  .modal-body
  .verification-modal-content
  .v-otp-input {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 25px;
  font-size: 14px;
  color: #ffffff;
  border: 1px solid transparent;
  margin: 15px 0px;
  gap: 70px;
  background-color: transparent;
}
.verfication-modal
  .modal-content
  .modal-body
  .verification-modal-content
  .v-otp-input
  input {
  color: #4a555f;
  font-size: 18px;
  border: 1px solid #4a555f;
  padding: 12px 15px;
  width: 100%;
  text-align: center;
  border-radius: 6px;
}
.verfication-modal
  .modal-content
  .modal-body
  .verification-modal-content
  .resend-otp {
  text-align: center;
  font-size: 11px;
}
.verfication-modal
  .modal-content
  .modal-body
  .verification-modal-content
  .resend-otp
  span {
  color: #061e40;
  font-weight: bold;
}
.verfication-modal
  .modal-content
  .modal-body
  .verification-modal-content
  .d-btn
  button {
  padding: 20px;
  margin: 10px 0px;
  width: 100%;
  text-transform: capitalize;
}
.verfication-modal
  .modal-content
  .modal-body
  .verification-modal-content
  .term-conditon {
  font-size: 12px;
  color: #667b99;
  text-align: center;
}
.verfication-modal
  .modal-content
  .modal-body
  .verification-modal-content
  .term-conditon
  a {
  text-decoration: none;
  color: #061e40;
  margin: 0px 3px;
  font-weight: 600;
}

@media only screen and (max-width: 776px) {
  .verfication-modal .modal-content .modal-body .verification-modal-content .v-otp-input {
    gap: 30px;
    
}
}
