.user-payment-modal .payment-methods {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 20px;
  border-radius: 4px;
  box-shadow: 0px 14px 64.2px -27px rgba(5, 52, 83, 0.2);
  margin-bottom: 15px;
}
.user-payment-modal .payment-methods .p-method-img-text {
  display: flex;
  gap: 10px;
  align-items: center;
}
.user-payment-modal .payment-methods .p-method-img-text .payment-img img {
  border-radius: 50%;
}
.user-payment-modal
  .payment-methods
  .p-method-img-text
  .payment-text
  .p-mehtod-name {
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}
.user-payment-modal
  .payment-methods
  .p-method-img-text
  .payment-text
  .p-method-third {
  font-weight: bold;
  color: #d3d3d3;
}

.user-payment-modal
  .payment-methods
  .p-method-img-text
  .payment-text
  .p-method-detail {
  color: #667b99;
  font-size: 13px;
  width: 85%;
}
#select-radio {
  accent-color: #061e40;
}
.user-payment-modal .d-btn button {
  padding: 20px;
  margin: 10px 0px;
  width: 100%;
  text-transform: capitalize;
}
