.loading-spinner-smallLoader {
  width: 18px;
  height: 18px;
  border: 4px solid lightgrey;
  /* Light grey */
  border-top: 4px solid #24c7d7;
  /* Black */
  border-radius: 100%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container-smallLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* loading Spinner */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}