.d-sum-gradient {
  box-shadow: 0px 14px 64.2px -27px rgba(5, 52, 83, 0.2);
  padding: 20px;
  background-color: #ffffff;
  margin-bottom: 5px;
}
.summary-heading {
  font-weight: bold;
  color: #061e40;
  font-size: 14px;
}

.payment-summary-modal .payment-summary-content .summmary-bima .bima-content {
  font-size: 13px;
  color: #909090;
}

.payment-summary-modal .payment-summary-content .transaction-summary {
  display: flex;
  align-items: center;
}
.payment-summary-modal
  .payment-summary-content
  .transaction-summary
  .transcation-img-text {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
  border-right: 1px solid #9ba6a76b;
}
.payment-summary-modal
  .payment-summary-content
  .transaction-summary
  .transcation-img-text
  .transaction-text
  .trans-text {
  color: #667b99;
  font-size: 12px;
}
.payment-summary-modal
  .payment-summary-content
  .transaction-summary
  .transcation-img-text
  .transaction-text
  .trans-acc-name {
  color: #061e40;
  font-weight: 600;
  font-size: 16px;
}

.payment-summary-modal
  .payment-summary-content
  .transaction-summary
  .account-detail {
  margin-left: 20px;
}
.payment-summary-modal
  .payment-summary-content
  .transaction-details
  .bronze-text {
  padding: 8px 0px;
  border-bottom: 1px solid #ededed;
}
.payment-summary-modal
  .payment-summary-content
  .transaction-summary
  .account-detail
  .acc-text {
  color: #667b99;
  font-size: 12px;
}
.payment-summary-modal
  .payment-summary-content
  .transaction-summary
  .account-detail
  .acc-number {
  color: #110844;
  font-size: 16px;
}
.payment-summary-modal .payment-summary-content .pading-bot {
  padding: 10px 20px;
}
.payment-summary-modal
  .payment-summary-content
  .transaction-details
  .daily-charges {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  border-bottom: 1px solid #ededed;
}
.payment-summary-modal
  .payment-summary-content
  .transaction-details
  .daily-charges
  .ammount {
  font-size: 12px;
  color: #667b99;
}
.payment-summary-modal
  .payment-summary-content
  .transaction-details
  .daily-charges
  .ammount
  span {
  font-size: 15px;
  color: #110844;
  font-weight: 600;
  margin-right: 3px;
}
.payment-summary-modal
  .payment-summary-content
  .transaction-details
  .daily-charges
  .s-h-color {
  color: #667b99;
  font-weight: lighter;
}
.payment-summary-modal .payment-summary-content .transaction-details .tax {
  align-items: end;
  border-bottom: none;
}
.payment-summary-modal .payment-summary-content .total-am {
  padding: 10px 20px;
  box-shadow: 0px 14px 64.2px -15px rgba(5, 52, 83, 0.2);
}
.payment-summary-modal .payment-summary-content .total-am .daily-charges {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payment-summary-modal
  .payment-summary-content
  .total-am
  .daily-charges
  .ammount {
  font-size: 12px;
  color: #667b99;
}
.payment-summary-modal
  .payment-summary-content
  .total-am
  .daily-charges
  .ammount
  span {
  font-size: 15px;
  color: #110844;
  font-weight: 600;
  margin-right: 3px;
}
.payment-summary-modal .d-btn button {
  padding: 20px;
  margin: 10px 0px;
  width: 100%;
  font-weight: 600;
  text-transform: capitalize;
}
.payment-summary-modal .d-btn .d-button {
  background-color: transparent;
  border: 1px solid #4a555f;
  color: #4a555f;
}

@media screen and (max-width: 600px) {
  .payment-summary-modal .payment-summary-content .transaction-summary {
    flex-direction: column;
  }
  .payment-summary-modal
    .payment-summary-content
    .transaction-summary
    .transcation-img-text {
    padding-bottom: 10px;
    border-bottom: 1px solid #9ba6a76b;
  }
  .payment-summary-modal
    .payment-summary-content
    .transaction-summary
    .account-detail {
    margin-left: 0px;
    margin-top: 5px;
  }
}
