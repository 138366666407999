.succes-modal .modal-content {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) -1.42%,
      rgba(255, 255, 255, 0) -1.41%,
      rgba(1, 205, 222, 0.1) 100%
    ),
    #ffffff;
}
.succes-modal .modal-header {
  border-bottom: none;
}
.succes-modal .succes-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 76%;
  text-align: center;
  padding: 30px 0px;
}
.succes-modal .succes-content .heading {
  color: #061e40;
  font-size: 24px;
  font-weight: 600;
  margin: 10px 0px;
}
.succes-modal .succes-content .content {
  color: #667b99;
  font-size: 16px;
}
.succes-modal .succes-content .d-btn button {
  padding: 20px 128px;
  text-transform: capitalize;
  margin-top: 20px;
}
