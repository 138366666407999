.landing-section {
  background-image: url(../Assets/imgs/landing/landing-bg.webp);
  min-height: 100vh;
  background-position: center top;
  padding: 0;
  margin: 0;
  padding-bottom: 10px;
}

.landing-section .landing-content {
  max-width: 375px;
  color: #ffffff;
  margin: auto;
  width: 100%;
  text-align: center;
}

.landing-section .landing-content .logo-image {
  padding: 16px 0px;
}

.landing-section .landing-content .fusion-form {
  padding: 25px;
  border-radius: 8px;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) -1.42%,
      rgba(255, 255, 255, 0) -1.41%,
      rgba(1, 205, 222, 0.1) 100%),
    #ffffff;
  box-shadow: 0px 30.0965px 23.7232px rgba(152, 224, 137, 0.29),
    -14.1631px -3.54077px 21.3298px rgba(1, 205, 222, 0.09);
}

.landing-section .landing-content .fusion-form .error {
  color: #e21a22;
  font-weight: 600;
  font-size: 13px;
}

.landing-section .landing-content .fusion-input {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: 7px;
  font-size: 14px;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 15px;
}

.landing-section .landing-content .fusion-input span {
  font-size: 13px;
  border: 1px solid #d7fbff;
  color: #6a6a6c;
  padding: 15px 10px;
  border-radius: 8px;
}

.landing-section .landing-content .fusion-input input {
  background: inherit;
  color: #6a6a6c;
  font-size: 13px;
  border: 1px solid #d7fbff;
  padding: 15px 6px;
  width: 100%;
  border-radius: 8px;
}

.landing-section .landing-content button {
  width: 100%;
  border-radius: 8px;
  padding: 15px 20px;
  font-size: 12px;
  color: #ffffff;
  border: 1px solid transparent;
  background-color: #061e40;
  font-weight: 700;
  margin: 10px 0px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  letter-spacing: 1px;
}

.landing-section .landing-content button:hover {
  opacity: 0.7;
}

.landing-section .landing-content .text {
  color: #0a2445;
  font-weight: 600;
  font-size: 16px;
}

.landing-section .landing-content .charges-text {
  font-size: 13px;
  color: #393939;
  font-weight: 600;
}

.landing-section .landing-content .term-conditon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 13px;
}

.landing-section .landing-content .term-conditon a {
  text-decoration: underline;
  color: #9f9f9f;
}

.landing-section .landing-content .term-conditon a:hover {
  opacity: 0.7;
}

.landing-section .landing-content .term-conditon .dot {
  border: 1px solid transparent;
  height: 5px;
  border-radius: 50px;
  width: 5px;
  background-color: #bcbcbc;
}

/* PIN page */
.landing-section .landing-content .otp-form {
  padding: 25px;
}

.landing-section .landing-content .otp-form .fusion-input {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 25px;
  font-size: 14px;
  color: #ffffff;
  border: 1px solid transparent;
  margin: 15px 0px;
  gap: 40px;
  background-color: transparent;
}

.landing-section .landing-content .otp-form .fusion-input input {
  color: black;
  font-size: 13px;
  border: 2px solid #d7fbff;
  padding: 12px 10px;
  width: 100%;
  text-align: center;
}

.landing-section .landing-content .otp-form .fusion-input input:hover {
  cursor: pointer;
}

.landing-section .landing-content .otp-form .charges-text span {
  font-weight: 600;
  margin-left: 2px;
  color: #e21a22;
}

.timer-text span {
  cursor: pointer;
}

@media only screen and (max-width: 776px) {
  .landing-section {
    background-position: center top;
  }

  .landing-section .landing-content .landing-home-image {
    padding: 0px 30px;
  }

  .landing-section .landing-content .fusion-form {
    box-shadow: 0px -23px 16.0214px rgba(1, 205, 222, 0.1);
  }
}