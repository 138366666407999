.modal-header .btn-close {
  border: solid 1px;
  border-radius: 50%;
  font-size: 10px;
  margin-right: 0;
}

.modal-title {
  font-weight: 600;
}

.provider-modal .provider-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  box-shadow: 0px 5px 10px rgba(5, 52, 83, 0.2);
  background: #fff;
  margin-bottom: 15px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.provider-modal {
  padding: 20px 0px;
}

.provider-modal .right-image {
  position: relative;
  z-index: 3;
  width: 25px;
}

.provider-modal .pm_info {
  display: flex;
  align-items: center;
  gap: 13px;
}

.provider-modal .efu-image img {
  width: 49px;
}

.provider-modal .bg-one {
  background-color: rgba(222, 248, 251, 1);
  position: absolute;
  right: 0px;
  top: -1px;
  z-index: 2;
  width: 109px;
  height: 90px;
  border-top-left-radius: 200px;
}

/* slider */

.insur-slider .slick-track {
  width: 100% !important;
}

.insur-slider .download-btn {
  margin-top: 20px;
}

.insur-slider .modal-content-body {
  padding: 40px 15px;
  background: #fff;
  border-radius: 10px;
  margin: 54px 0px;
  width: 97% !important;
  display: flex !important;
}

.insur-slider .modal-content {
  background: transparent;
  position: relative;
}

.insur-slider .modal-dialog.modal-xl {
  /* transform: none;
    margin: 0; */
}

.insur-slider .modal-parent {
  background-color: transparent;
  border-radius: 16px;
  /* display: flex; */
}

.insur-slider .modal-desc {
  margin: 0px;
  font-size: 13px;
  color: #454545;
}

.insur-slider .new-modal-heading {
  margin-bottom: 0px;
}

.insur-slider .download-btn a {
  display: inline-block;
  margin-top: 0px;
  transition: all 0.2s linear;
  cursor: pointer;
}

.insur-slider .download-btn a:hover {
  background: #000;
  color: #fff;
  cursor: pointer;
}

.insur-slider ._m-img img {
  min-width: 60px;
  height: auto;
}

.insur-slider .slick-prev:before,
.slick-next:before {
  font-size: 0px !important;
}

.insur-slider .slick-prev {
  position: absolute;
  left: 45%;
  top: 94%;
  z-index: 1;
}

.insur-slider .slick-next {
  right: 47%;
  top: 94%;
}

.insur-slider .modal-parent .swiper-wrapper {
  padding: 60px 0px;
}

.modal-parent .swiper-button-next::after {
  background-image: url("../Assets/imgs/next-btn.webp") !important;
  background-size: cover !important;
  min-width: 40px !important;
  min-height: 40px !important;
}

.modal-parent .swiper-button-prev::after {
  background-image: url("../Assets/imgs/prev-btn.webp") !important;
  background-size: cover !important;
  min-width: 40px !important;
  min-height: 40px !important;
}

/* detail-modal */

.detail-modal .detail-title {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.detail-modal .ins_heading {
  color: #061E40;
  font-weight: 600;
  font-size: 20px;
  border-bottom: 1px solid #CDD2D3;
  padding-bottom: 5px;
  margin: 20px 0 15px 0px;
}

.plan_tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.plan_tooltip .plan_tooltiptext {
  visibility: hidden;
  width: 500px;
  color: #000 !important;
  background-color: #fff;
  color: #fff;
  /* text-align: center; */
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 15px 39.7px -12px rgba(0, 0, 0, 0.3);
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -50px;
  right: 105%;
}

.tooltip-title {
  color: #061e40;
  font-weight: 600;
}

.plan_tooltiptext-desc {
  color: #667b99;
}

.plan_tooltip:hover .plan_tooltiptext {
  visibility: visible;
}

.detail-modal .detail-content .detail-left {
  max-height: 90%;
  overflow-y: scroll;
}

.detail-modal .detail-card.a {
  /* background-image: url("../Assets/imgs/card-bg-1.webp"); */
  background-size: cover;
  background-position: center;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
}

.detail-modal .detail-card.b {
  background-image: url("../Assets/imgs/card-bg-2.webp");
  background-size: cover;
  background-position: center;
  padding: 10px;
  border-radius: 15px;
}

.detail-modal .detail-card.c {
  background-image: url("../Assets/imgs/card-bg-3.webp");
}

.detail-modal .detail-card.d {
  background-image: url("../Assets/imgs/card-bg-4.webp");
}

.detail-modal .detail-card.e {
  background-image: url("../Assets/imgs/card-bg-5.webp");
}

.detail-modal .detail-card label {
  display: inline-block;
  margin-left: 10px;
  color: #061e40;
  font-size: 18px;
  font-weight: 600;
}

.detail-modal .detail-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.detail-modal .detail-card hr {
  margin: 1px 0px !important;
  padding: 0px;
}

.detail-modal .detail-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-modal .detail-price span {
  color: #110844;
  font-size: 17px;
  font-weight: 600;
}

.detail-modal .charge {
  font-size: 14px;
}

.detail-modal .d-price {
  font-size: 11px;
}

.detail-modal .term-link a {
  text-decoration: none;
  color: #2196f3;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.2s linear;
}

.detail-modal .term-link a:hover {
  opacity: 0.6;
}

.detail-modal .selection-section {
  text-align: center;
  margin-top: 15px;
}

.detail-modal .check-box label {
  font-size: 13px;
  color: #667b99;
  width: 100%;
  text-align: center;
}

.detail-modal .check-box input {
  width: 20px;
}

.check-box {
  display: flex;
}

.detail-modal .d-btn button {
  width: 100%;
  padding: 13px;
  border-radius: 10px;
  text-transform: capitalize;
}

.detail-modal .d-btn {
  margin-top: 20px;
}

.detail-modal .detail-left ul {
  padding-left: 25px !important;
  list-style-type: disc !important;
}

.detail-modal .detail-icon {
  text-align: center;
  margin: 60px 0px;
}
.detail-modal .detail-icon img{
  width: 100px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e8e8e8;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* mobile */
@media (max-width: 767px) {
  .insur-slider .modal-content {
    left: 0;
  }

  .insur-slider .slick-prev {
    left: 37%;
  }

  .insur-slider .slick-next {
    right: 34%;
  }

  .plan_tooltip .plan_tooltiptext {
    width: 300px;
  }
}